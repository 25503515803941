export class Functions{
    static getFunction(name){
        let obj;
        switch(name.toLocaleUpperCase()){
            case "SPLIT":
                obj=[new Property("value","string"),new Property("delimiter","string"),new Property("index","number")];
                break;
            case "LENGTH":
                obj=[new Property("value","string")];
                break;
            case "SUBSTR":
                obj=[new Property("value","string"),new Property("Start","number"),new Property("end","number")];
                break;
            case "ESCAPESQL":
                obj=[new Property("value","string")];
                break;
            case "UNESCAPESQL":
                obj=[new Property("value","string")];
                break;
            case "CURRENTDATE":
                obj=[];
                break;
            case "DATEFORMAT":
                obj=[new Property("date","string"),new Property("format","string")];
                break;
            case "DATECONVERTER":
                obj=[new Property("date","string"),new Property("format","string")];
                break;
            case "XMLTOTABLE":
                obj=[new Property("xml","string"),new Property("xpath","string")];
                break;
            case "ENCODE":
                obj=[new Property("value","string"),new Property("charset","string")];
                break;
            case "DECODE":
                obj=[new Property("value","string"),new Property("charset","string")];
                break;
            case "UPPERCASE":
                obj=[new Property("value","string")];
                break;
            case "LOWERCASE":
                obj=[new Property("value","string")];
                break;
            case "TABLETOSTRING":
                obj=[new Property("table","table"),new Property("column","string")];
                break;
            case "TABLETOJSON":
                obj=[new Property("table","table")];
                break;
			case "LOOKUP":
                obj=[new Property("table","table"),new Property("inputcolumn","string"),new Property("value","string"),new Property("outputcolumn","string")];
                break;
            case "COUNT":
                obj=[new Property("table","table")];
                break;
            case "GENERATEHASHCODE":
                obj=[new Property("value","string")];
                break;
            default :
                obj=[];
                break;
        }
        return obj;
    }
}

export class NodeProperty{
    constructor(name, property){
        this.property=new Object();
        this.property[name]=property;
        this.node = null;
    }
    getProperty(name){
        return this.property[name];
    }
    setProperty(name, property){
        return this.property[name] = property;
    }
    setNode(name, property){
        return this.property[name] = property;
    }
    addProperty(name, property){
        return this.property[name] = property;
    }
}

export class Property{
    constructor(name,type){
        this.name=name;
        this.type=type;
        this.variable="";
        this.value="";
        this.node = null;
    }
    setValue(value){
        this.value=value;
    }
    setVariable(value){
            this.value=value;
    }
    getValue(){
        return this.value;
    }
    getVariable(){
        return this.value;
    }
    getName(){
        return this.name;
    }
    getPropertyValue(){
        if (typeof (this.value) == 'string')
            return this.value.trim() != '' ? this.value : this.variable;
        else
            return this.value;
    }
}